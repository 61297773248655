<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Autorizadas</h1>
      <div class="container-btns">
        <button class="btn btn-laranja" @click="adicionarAutorizada">Adicionar</button>
      </div>
      <!-- Filtro de busca -->
      <form @submit.prevent="pesquisaForm()" class="formulario-container">
        <div class="formulario-linha linha-quadrupla">
          <!-- Nome -->
          <div class="formulario-item">
            <label for="razaoSocial" class="formulario-item-label">Razão Social</label>
            <input name="razaoSocial" type="text" v-model="form.razaoSocial" />
          </div>
          <!-- Label CNPJ -->
          <div class="formulario-item">
            <label for="cnpj" class="formulario-item-label">CNPJ</label>
            <input name="cnpj" type="text" v-model="form.cnpj" v-mask="'##.###.###/####-##'" />
          </div>
          <!-- Label SAP -->
          <div class="formulario-item">
            <label for="sap" class="formulario-item-label">SAP</label>
            <input name="sap" type="text" v-model="form.sap" v-mask="'##########'" />
          </div>
          <!-- Label UF -->
          <div class="formulario-item">
            <label class="formulario-item-label">UF</label>
            <multiselect v-model="form.idUf" label="descricao" track-by="id" :options="regioesBr" group-values="estados"
              group-label="descricao" :searchable="true" :multiple="false" placeholder="Selecione"
              select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"><span slot="noResult">Item
                não encontrado</span>
            </multiselect>
          </div>
        </div>
        <div class="formulario-linha linha-tripla">
          <!-- Label Autorizada -->
          <div class="formulario-item">
            <label class="formulario-item-label">Autorizada</label>
            <multiselect @select="listarComboAutorizada" v-model="form.idAutorizada" label="descricao" track-by="id"
              :options="tipo" :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Selecionado" selected-label="Selecionado" :allow-empty="false" />
          </div>
          <!-- Label Assistência/Franquia -->
          <div class="formulario-item" v-if="form.idAutorizada.length != 0">
            <label class="formulario-item-label">{{ verificarLabel() }}</label>
            <multiselect v-model="form.id" label="descricao" track-by="id" :options="verificarArray()"
              :searchable="true" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" :allow-empty="true"><span slot="noResult">Item não
                encontrado</span></multiselect>
          </div>
          <!-- Label Região -->
          <div class="formulario-item">
            <label class="formulario-item-label">Região</label>
            <multiselect v-model="form.idRegiao" label="descricao" track-by="id" :options="regiao" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" :allow-empty="true" />
          </div>
          <!-- Label Grupo -->
          <div class="formulario-item">
            <label class="formulario-item-label">Grupo</label>
            <multiselect v-model="form.idGrupo" label="descricao" track-by="id" :options="grupo" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" :allow-empty="true" />
          </div>
          <!-- Label Status -->
          <div class="formulario-item">
            <label class="formulario-item-label">Status</label>
            <multiselect v-model="form.idStatus" label="descricao" track-by="id" :options="opcoes" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" :allow-empty="true" />
          </div>
        </div>
        <div class="container-btns">
          <button type="button" class="btn btn-cancelar" @click="limparDados">
            Limpar
          </button>
          <button type="submit" class="btn btn-laranja">Filtrar</button>
        </div>
      </form>

      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados</p>
      </div>

      <Tabela v-else :colunas="colunas" :linhas="autorizadas" :paginacao="true" :pagina="pagina"
        :totalPaginas="totalPaginas" :funcProximaPag="proximaPag" :funcAnteriorPag="anteriorPag" :grupoAutorizadas="grupo" />

    </div>
  </div>
</template>

<script>
import {
  listarTipoAutorizada,
  listarAutorizadas,
  listarAutorizadasCadastradas,
  listarRegioes,
  listarGrupo
} from "@/services/autorizadas";
import { functions, objects, strings } from '@/assets/js'
import Multiselect from "vue-multiselect";
import Tabela from "@/components/Tabela/Tabela.vue";
import { opcoes, brEstados } from '@/utils/json'

export default {
  name: "AutorizadaListar",
  components: {
    Multiselect,
    Tabela
  },
  data() {
    return {
      opcoes: opcoes.status,
      regioesBr: brEstados.ufRegiao,
      colunas: [
        { key: "id", titulo: 'Id', tamanho: 'pequeno', link: false },
        { key: "nomeFantasia", titulo: 'Nome Fantasia', tamanho: 'porcentagem', link: false },
        { key: "cnpj", titulo: 'CNPJ', tamanho: 'medio-240', link: false },
        { key: "codigoSAP", titulo: 'Código SAP', tamanho: 'medio-150', link: false },
        { key: "autorizadaGrupoId", titulo: 'Grupo', tamanho: 'medio-150', link: false },
        { key: "status", titulo: 'Status', tamanho: 'medio-150', link: false },
        { key: "", titulo: '', tamanho: 'btns', link: true, func: this.detalhesAutorizada },
      ],
      semDados: false,
      pagina: 1,
      qtdPagina: 10,
      totalPaginas: 0,
      formFiltro: {},
      form: {
        razaoSocial: "",
        id: [],
        cnpj: "",
        sap: "",
        idUf: "",
        idAutorizada: [],
        idAssistencia: [],
        idFranquia: [],
        idStatus: [],
        idRegiao: [],
        idGrupo: [],
      },
      autorizadas: [],
      tipo: [],
      assistencias: [],
      franquias: [],
      assEFranq: [],
      regiao: [],
      grupo: [],
      msgResult: "",
    };
  },

  mounted() {
    this.popularRegiao();
    this.popularGrupo();
    this.listarTipos();
    this.popularAutorizadasCadastradas();
  },

  methods: {
    verificarLabel() {
      return this.form.idAutorizada.id == 1 ? "Assistência" : "Franquia"
    },
    verificarArray() {
      return this.form.idAutorizada.id == 1 ? this.assistencias : this.franquias
    },
    listarComboAutorizada(autorizada) {
      /*
        Esta funcao é apenas disparada quando o usuário seleciona alguma opcao do select
        e verifica se ja fez alguma requisiçao para aquele id
      */
      this.form.id = []
      switch (autorizada.id) {
        case 1:
          // assistencia
          if (!this.assistencias.length) return listarAutorizadas(autorizada.id).then(res => {
            this.assistencias = res.data
          })
          break;
        case 3:
          // autorizada
          if (!this.franquias.length) return listarAutorizadas(2).then(res => {
            this.franquias = res.data
          })
          break;
        // case 3:
        //   // autorizada/assistencia
        //   if (!this.assEFranq.length) console.log('está sem dados')
        //   break;
        default:
          console.log('erro na funcao listarComboAutorizada')
          break;
      }
    },
    detalhesAutorizada(autorizadaId) {
      return `/autorizadas/editar/${autorizadaId}`
    },
    popularRegiao() {
      listarRegioes().then(resp => {
        this.regiao = resp.data
      })
    },
    popularGrupo() {
      listarGrupo().then(resp => {
        this.grupo = resp.data
      })
    },
    listarTipos() {
      listarTipoAutorizada().then(resp => {
        this.tipo = resp.data.filter(tipo => tipo.id != 2 && tipo.id != 4)
      });
    },
    pesquisaForm() { // Funçao para evitar requisiçao desnecessária
      if (objects.objetosIguais(this.formFiltro, this.tratamentoDadosForm())) { // caso todos os campos sao iguais
        return this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Nenhum campo foi alterado. Terá que mudar algum filtro para fazer uma nova busca',
          tipo: "erro",
        })
      }
      this.pagina = 1
      this.popularAutorizadasCadastradas()
    },
    tratamentoDadosForm() {
      const data = {
        id: functions.verificarDadoValido(this.form.id) ? this.form.id.id : 0,
        razaoSocial: this.form.razaoSocial,
        cnpj: strings.removerCaracteresEspeciais(this.form.cnpj),
        codigoSAP: this.form.sap,
        uf: functions.verificarDadoValido(this.form.idUf) ? this.form.idUf.descricao : "",
        autorizadaTipoId: functions.verificarDadoValido(this.form.idAutorizada) ? this.form.idAutorizada.id : 0,
        autorizadaRegiaoId: functions.verificarDadoValido(this.form.idRegiao) ? this.form.idRegiao.id : 0,
        autorizadaGrupoId: functions.verificarDadoValido(this.form.idGrupo) ? this.form.idGrupo.id : 0,
        status: functions.verificarDadoValido(this.form.idStatus) ? this.form.idStatus.value : null
      }

      return data
    },
    popularAutorizadasCadastradas() {
      listarAutorizadasCadastradas(this.tratamentoDadosForm(), this.pagina, this.qtdPagina).then(resp => {
        this.formFiltro = this.tratamentoDadosForm()
        if (resp.data.lista.length) {
          this.semDados = false
          this.autorizadas = resp.data.lista
          this.totalPaginas = resp.data.paginacao.totalPaginas
          return;
        }
        this.semDados = true
      })
    },
    adicionarAutorizada() {
      this.$router.push("/autorizadas/adicionar");
    },
    limparDados() {
      this.form = {
        id: [],
        razaoSocial: "",
        cnpj: "",
        sap: "",
        idUf: [],
        idAutorizada: [],
        idAssistencia: [],
        idFranquia: [],
        idRegiao: [],
        idGrupo: [],
        idStatus: []
      }
    },
    proximaPag() {
      this.pagina++;
      this.popularAutorizadasCadastradas();
    },
    anteriorPag() {
      this.pagina--;
      this.popularAutorizadasCadastradas();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>